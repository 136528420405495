import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizQuestion1', 'quizQuestion2', 'quizQuestion3', 'quizQuestion4', 'quizQuestion5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "quiz"
    }}>{`Quiz`}</h1>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion1" once={true} question="QUESTION 01: Now that my child is a teenager, I should let them choose what they eat." fallbackResponse={<p>
        The correct answer is: <b>False</b>. Parents still play an important role in helping their teenagers to make
        healthy food choices. Instead of allowing your teenager to choose all of their own food, you could involve them
        in choosing and preparing nutritious meals with you. (See Encourage a healthy diet{' '}
        <GoTo to="/m7-health-habits/03-healthy-diet" mdxType="GoTo">Part I</GoTo> and{' '}
        <GoTo to="/m7-health-habits/04-how-to-healthy-diet" mdxType="GoTo">Part II</GoTo>).
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion2" once={true} question="QUESTION 02: It is a good idea to have family rules about screen use that all family members agree to and follow." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Having family rules about screen use can help you and your teenager to keep
        your screen use healthy. Your teenager (and other family members) are more likely to follow the rules if they
        are involved in developing the rules, and if everyone follows them (including you!). (See{' '}
        <GoTo to="/m7-health-habits/09-healthy-screen-use" mdxType="GoTo">Encouraging healthy screen use habits</GoTo>).
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion3" once={true} question="QUESTION 03: My teenager should do 60 minutes of exercise every day, all in one session." fallbackResponse={<p>
        The correct answer is: <b>False</b>. Your teenager’s daily exercise does not have to be done all in one session;
        it can be spread over the day. See{' '}
        <GoTo to="/m7-health-habits/05-daily-exercise" mdxType="GoTo">Encourage daily exercise</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion4" once={true} question="QUESTION 04: I should encourage my teenager to do some relaxing activities before bedtime to help them wind down from the day." fallbackResponse={<p>
        The correct answer is: <b>True</b>. At night, you should encourage your teenager to wind down by dimming the
        lights, doing something relaxing, and not using electronic devices. Stimulating activities should be avoided in
        the hour before bedtime. These are all good sleep habits. See{' '}
        <GoTo to="/m7-health-habits/10-sleep-habits" mdxType="GoTo">Encourage good sleep habits</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion5" once={true} question="QUESTION 05: It’s okay to let my teenager have a few sips of my alcoholic drink at home to teach them how to drink responsibly." fallbackResponse={<p>
        The correct answer is: <b>False</b>. Alcohol and drug use is harmful for the health and brain development of
        teenagers, so total abstinence is the best option. See{' '}
        <GoTo to="/m7-health-habits/12-no-alcohol-drugs" mdxType="GoTo">Ensure no alcohol and other drug use</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <SingleImage gridSize={4} smallGridSize={8} src="/images/shared/51.1-01.svg" alt="thinking woman" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      